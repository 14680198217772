<template>
    <div class="home">
        <div class="troduce">
            <div class="w">
                <div class="welcomeImg">
                    <img src="../assets/images/TDT/welcome.png" alt="">
                </div>
                <h1 class="welcome">
                    <span>更安全、便捷的数字资产钱包</span>
                </h1>
                <p class="ph">
                    让你安全、便捷的探索区块链
                </p>
                <div class="phs">
                    <div class="Android fl">
                        <img src="../assets/images/download.png" alt="">
                        <span>Android</span>
                    </div>
                    <div class="code fl">
                        <img src="../assets/images/code.png" alt="">
                    </div>
                    <div class="Chrome fr">
                        <img src="../assets/images/Chrome.png" alt="">
                        <span>Chrome</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import downloadjs from 'downloadjs'
import Discordss from '@/assets/images/Discordss.png'
import Discord1 from '@/assets/images/Discord1.png'
import GitHub from '@/assets/images/GitHub.png'
import GitHub1 from '@/assets/images/GitHub1.png'
import telegram from '@/assets/images/telegram.png'
import telegram1 from '@/assets/images/telegram1.png'
import twitter from '@/assets/images/twitter.png'
import twitter1 from '@/assets/images/twitter1.png'
import CountTo from 'vue-count-to'

export default {
    name: 'home-page',
    data() {
        return {
            startVal: 0,
            startVals: 0,
            startValss: 0,
            endVal: 0,
            endVals: 0,
            endValss: 0,
            duration: 2000,
            popupState: true,
            transactions: '',
            height: '',
            TotalStake: '',
            timer: null,
            startTime: 0, // 初始开始请求时间
            currentIndex: 0,
            userShow: true,
            terraceList: [{
                id: 1,
                path1: Discordss,
                path2: Discord1,
                transfer: ''
            }, {
                id: 2,
                path1: GitHub,
                path2: GitHub1,
                transfer: ''
            }, {
                id: 3,
                path1: telegram,
                path2: telegram1,
                transfer: ''
            }, {
                id: 4,
                path1: twitter,
                path2: twitter1,
                transfer: ''
            }],
            tabChange: '',
            randomNumber: 1,
            randomNumbers: 1,
            randomNumberss: 1
        }
    },
    components: {
        CountTo
    },
    methods: {
        download() {
            const lang = this.$store.getters.getLanguage
            // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
            const pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' + lang + '.pdf'
            downloadjs(pdfUrl)
        },
        popupClick() {
            this.popupState = false
        },
        jumper(nameOrURI) {
            const re = /^(ftp|https?):\/\//
            if (re.test(nameOrURI)) {
                if (nameOrURI === 'https://www.cgxyex.co/#/' || nameOrURI === 'https://www.cgxyex.com/#/') {
                    if (window.localStorage.getItem('language') === 'zh-CN') {
                        window.open('https://www.cgxyex.co/#/')
                    } else {
                        window.open('https://www.cgxyex.com/#/')
                    }
                } else {
                    window.open(nameOrURI)
                }
            } else {
                const uri = this.$router.resolve({ name: nameOrURI }).href
                location.href = uri
            }
        },
        async getTransactions() {
            this.randomNumber = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/', {
                jsonrpc: '2.0',
                method: 'getCoinInfo',
                params: [
                    1
                ],
                id: 250
            })
            let txCount = res.result.txCount
            txCount = this.addDou(txCount.toString())
            this.transactions = txCount
            this.endVal = res.result.txCount
            this.startVal = this.endVal - this.randomNumber
        },

        async getBlockheight() {
            this.randomNumbers = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/',
                {
                    jsonrpc: '2.0',
                    method: 'getBestBlockHeader',
                    params: [
                        1
                    ],
                    id: 165
                }
            )
            let height = res.result.height
            height = this.addDou(height.toString())
            this.height = height
            this.endVals = res.result.height
            this.startVals = this.endVals - this.randomNumbers
        },

        async getTotalStake() {
            this.randomNumberss = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/',
                {
                    jsonrpc: '2.0',
                    method: 'getCoinInfo',
                    params: [
                        1
                    ],
                    id: 508
                }
            )
            let TotalStake = res.result.consensusTotal
            TotalStake = this.thousands(TotalStake.toString())
            this.TotalStake = TotalStake
            this.endValss = parseFloat(res.result.consensusTotal.toString().slice(0, 9))
            this.startValss = this.endValss - this.randomNumberss
        },
        addDou(num) {
            const numArr = num.split('.')
            num = numArr[0]
            let result = ''
            while (num.length > 3) {
                result = ',' + num.slice(-3) + result
                num = num.slice(0, num.length - 3)
            }
            if (num) {
                result = num + result
            }
            return result
        },

        thousands(num) {
            num = num / 100000000000
            var str = num.toFixed(2).toString()
            var reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
            return str.replace(reg, '$1,')
        },

        time() {
            setInterval(() => {
                setTimeout(() => {
                    this.getBlockheight()
                    this.getTotalStake()
                    this.getTransactions()
                }, 0)
            }, 5000)
        },
        // pc点击跳转
        change: function (index) {
            if (index === 0) {
                window.open('https://discord.gg/XrUdhYY8')
            } else if (index === 1) {
                // window.open('https://github.com/icwchain')
            } else if (index === 2) {
                window.open('https://t.me/TridentChain')
            } else if (index === 3) {
                window.open('https://twitter.com/Trident_Chain')
            }
        },
        // 鼠标移入切换图片
        over(e) {
            this.terraceList[e].transfer = this.terraceList[e].path1
            this.terraceList[e].path1 = this.terraceList[e].path2
            this.tabChange = e
        },
        // 鼠标移出切换图片
        out(e) {
            this.terraceList[e].path1 = this.terraceList[e].transfer
            this.tabChange = e
        },
        // 移动端手指触碰
        gtouchstart(index) {
            // console.log('index', index)
            this.terraceList[index].transfer = this.terraceList[index].path1
            this.terraceList[index].path1 = this.terraceList[index].path2
            this.tabChange = index
        },
        // 移动端手指滑动
        gtouchmove(index) {
            // console.log('index1', index)
        },
        // 移动端手指离开
        gtouchend(index) {
            setTimeout(() => {
                this.terraceList[index].path1 = this.terraceList[index].transfer
            }, 1000)
            this.tabChange = index
        },
        // 移动端点击跳转页面
        learnMorefirst() {
            window.open('https://discord.gg/XrUdhYY8')
        },
        learnMoretwo() {
            // window.open('https://github.com/icwchain')
        },
        learnMorethree() {
            window.open('https://t.me/TridentChain')
        },
        learnMorefour() {
            window.open('https://twitter.com/Trident_Chain')
        }
    },
    created() {
        this.getTransactions()
        this.getBlockheight()
        this.getTotalStake()
        this.time()
    }

}
</script>
<style lang="less" scoped>
.home {

    .troduce {
        width: 100%;
        height: 1200px;
        margin: 0 auto;

        .w {
            position: relative;
        }

        iframe.ap.max {
            border: none;
        }

        .welcomeImg {
            position: absolute;
            width: 534px;
            height: 698px;
            left: 0;
            top: 243px;
        }

        .welcome {
            font-family: 'SourceHanSansCN-Bold';
            width: 618px;
            height: 48px;
            color: #282828;
            font-size: 46px;
            position: absolute;
            top: 417px;
            left: 582px;
            font-weight: bold;
        }

        .ph {
            font-family: 'SourceHanSansCN-Medium';
            width: 370px;
            height: 29px;
            color: #282828;
            font-size: 26px;
            position: absolute;
            top: 505px;
            left: 582px;
        }

        .phs {
            position: absolute;
            top: 613px;
            left: 582px;

            .Android {
                width: 204px;
                height: 60px;
                border-radius: 30px;
                background-color: #FFAA00;

                img {
                    margin: 17px 15px 17px 50px;
                }

                span {
                    color: #FFFFFF;
                    font-size: 17px;
                    line-height: 60px;
                }
            }

            .code {
                width: 61px;
                height: 61px;
                border-radius: 50%;
                border: 1px solid #282828;
                margin-left: 30px;
                margin-right: 30px;

                img {
                    margin: 17px;
                }
            }

            .Chrome {
                width: 204px;
                height: 60px;
                border-radius: 30px;
                border: 1px solid #282828;

                img {
                    margin: 17px 15px 17px 50px;
                }

                span {
                    color: #282828;
                    font-size: 18px;
                    line-height: 60px;
                }
            }
        }
    }

    .num {
        width: 100%;
        height: 750px;
        margin-top: 60px;

        .w {
            position: relative;
        }

        .secure {
            position: absolute;
            top: 100px;
            left: 0;
            width: 427px;
            margin-top: 10px;
            text-align: left;
            color: #282828;

            h3 {
                margin-top: 40px;
                font-size: 50px;
                font-weight: 700;
                line-height: 40px;
                font-family: 'SourceHanSansCN-Bold';
            }

            .p1 {
                margin-top: 30px;
                height: 135px;
                font-size: 30px;
            }

            .p2 {
                margin-top: 30px;
                height: 69px;
                font-size: 30px;
            }
        }

        .block {
            position: absolute;
            top: 0;
            right: 0;
        }

    }

    .advantage {
        width: 100%;
        height: 1010px;


        .w {
            position: relative;
        }

        .convenient {
            position: absolute;
            top: 300px;
            left: 0;
            z-index: 1;
        }

        .why {
            position: absolute;
            top: 500px;
            right: 0;
            width: 370px;

            h3 {
                font-weight: 700;
                font-size: 50px;
            }

            
            .p1 {
                margin-top: 30px;
                font-size: 28px;
            }

            .p2 {
                margin-top: 30px;
                font-size: 28px;
            }
        }
    }
}
</style>
<!--移动端-->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
    .home {
        background: url("../assets/images/TDT/m-bg.png") no-repeat center top !important;
        height: 61rem;

        .w {
            width: 6.1rem;
            margin: 0 auto;
        }

        .troduce {
            background: url(../assets/images/m-bghome.gif) no-repeat !important;
            background: none;
            background-size: 7.5rem 12.34rem !important;
            height: 13rem;
            // position: relative;

            .welcome {
                width: 5.07rem;
                font-size: 0.45rem;
                top: 2rem;
                left: -0.1rem;
            }

            .welcomeImg {
                display: none;
                position: absolute;
                top: 1.6rem;
                right: 0;
            }

            .ph {
                font-size: 0.24rem;
                padding: 0;
                top: 3rem;
                width: 5.92rem;
                left: -0.1rem;
                line-height: 0.34rem;
                color: #fff;
            }

            .phs {
                margin-top: 1rem;
            }

            .download {
                left: -0.1rem;
                width: 3.25rem;
                top: 6.2rem;
                height: 0.46rem;
                text-align: center;
                font-size: 0.13rem;
                font-weight: bold;
                color: #246EFF;
                line-height: 0.46rem;
                padding: 0 0;
                background: #fff;
                border: 1px solid #246EFF;
                border-radius: 0.41rem;
            }
        }

        .num {
            width: 6.94rem;
            // background-color: #fff;
            height: 11rem;
            margin: 0 0.3rem;
            border-radius: 0.33rem;

            .w {
                margin-top: -0.73rem;
            }

            .pcBlock {
                display: none;
            }

            .ydBlock {
                display: block;
                width: 6rem;
                height: 3rem;
            }

            .block {
                float: left;
                box-sizing: border-box;
                width: 100%;
                margin-top: 0.5rem;
                padding: 0;

                h3 {
                    margin-top: 0.50rem;
                    color: #000;
                    -webkit-text-fill-color: #000;
                    font-size: 0.8rem;
                }

                h5 {
                    margin-top: 0.3rem;
                    color: white;
                    font-size: 0.13rem;
                    width: 2.5rem;
                }
            }
        }

        .advantage {
            margin-top: 0rem;
            box-sizing: border-box;
            height: 12rem;
            // background-color: red;

            .w {
                width: 6.94rem;
            }

            .coin3 {
                display: none;
            }

            .why {
                padding-top: 0.75rem;
                margin: 0;
                width: 100%;
                height: 0rem;

                h2 {
                    margin-top: 0rem;
                    font-size: 0.41rem;
                    line-height: 0.26rem;
                }

                .hor {
                    margin-top: -1rem;
                }

                h5 {
                    font-size: 0.17rem;
                    line-height: 0.17rem;
                    margin-top: -1rem;
                }
            }

            .adv-child:nth-child(4) {
                margin: 0.5rem 0;
                height: 3.5rem;

                .content {
                    position: absolute;
                    width: 5.5rem;
                    font-size: 0.2rem;
                    margin-top: 0.6rem;
                    left: 0rem;
                    top: 0.5rem;
                }
            }

            .adv-child:nth-child(3) {
                margin-top: 0.8rem;
                height: 3rem;
            }

            .adv-child:nth-child(2) {
                margin-top: 0.5rem;
            }

            .adv-child {
                box-sizing: border-box;
                position: relative;
                margin: 0 auto;
                width: 6.94rem;
                height: 3rem;
                padding: 0;
                border-radius: 0.27rem;

                .title {
                    position: absolute;
                    width: 5.3rem;
                    font-size: 0.26rem;
                    line-height: 0.31rem;
                    top: 0.1rem;
                    left: 0rem;
                    margin: 0.19rem 0;

                    .titleBody {
                        padding-left: 0rem;
                        position: absolute;
                        top: 0.2rem;
                        left: 0.8rem;
                    }

                    .line {
                        height: 0.2rem;
                        position: absolute;
                        width: 0.06rem;
                        margin-top: 0.36rem;
                        top: -0.1rem;
                        left: 0.41rem;
                        border-radius: 3px;
                    }
                }

                .content {
                    position: absolute;
                    width: 5.5rem;
                    font-size: 0.2rem;
                    margin-top: 0.2rem;
                    left: 0rem;
                    top: 0.5rem;
                    padding: 0.5rem;
                }
            }

            .popupWrap {
                width: 4rem;
                height: 2rem;
                border-radius: 0.1rem 0.1rem 0px 0.1rem;
                padding: 0.15rem;
                position: fixed;
                bottom: 0.14rem;
                right: 0.18rem;
                z-index: 100;

                .close {
                    font-size: 0.3rem;
                    color: #fff;
                    position: absolute;
                    top: 10px;
                    right: 26px;
                    cursor: pointer;
                }

                .popup {
                    width: 100%;
                    height: 100%;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 6px;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                        background: rgba(0, 0, 0, 0.2);
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        background: rgba(0, 0, 0, 0.1);
                    }

                    .popupContent {
                        width: 100%;
                        min-height: 250px;
                        font-size: 0.14rem;
                        font-family: Source Han Sans SC;
                        color: #282828;

                        .popupTitle {
                            font-weight: bold;
                            margin-bottom: 6px;
                        }

                        .popupWarn {
                            color: #FF0000;
                            font-weight: bold;
                            margin: 6px 0;
                        }

                        .popupText {
                            line-height: 0.3rem;
                        }
                    }

                    .popupButton {
                        width: 1rem;
                        height: 0.3rem;
                        background: #3A5BE9;
                        font-family: PingFang SC;
                        font-size: 0.14rem;
                        color: #282828;
                        text-align: center;
                        line-height: 0.3rem;
                        font-weight: bold;
                        border-radius: 22px;
                        margin: 30px auto 0;
                        cursor: pointer;
                    }
                }
            }
        }

        .socialize {
            display: none;
        }

        .socialize1 {
            width: 100%;
            height: 9rem;
            display: block;
            // background: red;

            .terrace {
                padding-top: 0.2rem;
                margin: 0 auto;

                h2 {
                    margin-top: 0.35rem;
                    font-size: 0.4rem;
                    text-align: center;
                    color: white;
                }

                .hor {
                    margin-top: -1rem;
                    width: 0rem;
                }

                h5 {
                    font-size: 0.17rem;
                    line-height: 0.17rem;
                    margin-top: -1rem;
                    text-align: center;
                    color: white;
                }
            }

            .terraceList {
                box-sizing: border-box;
                float: left;
                width: 2.70rem;
                height: 2rem;
                margin: 0.5rem 0.23rem 0;
                line-height: 0.5rem;
                padding: 0.61rem 0.91rem;
                background: rgba(0, 0, 0, 0.3);

                img {
                    width: 0.78rem;
                    height: 0.78rem;
                }
            }

            .terraceList:first-child {
                margin-left: 0;
            }

            .terraceList:last-child {
                margin-right: 0;
            }

            .terraceList:nth-child(3) {
                margin-left: 0;
            }


            .Discord {
                margin-top: 5.5rem;

                h3 {
                    font-size: 0.24rem;
                    color: white;
                }

                p {
                    font-size: 0.22rem;
                    width: 6.68rem;
                    line-height: 0.38rem;
                    margin-top: 0.21rem;
                    color: white;
                }

                .edit {
                    width: 1.3rem;
                    margin-top: 0.1rem;
                    font-size: 0.18rem;
                    // background: red;
                    text-align: center;
                    padding: 0.1rem 0;
                    border-radius: 0.5rem;
                    border: 0.01rem solid #246EFF;
                    color: #246EFF;
                }
            }

        }

        .Friend {
            background: url(../assets/images/m-Friend.png) no-repeat center;
            box-sizing: border-box;
            width: 100%;
            height: 1.53rem;
            text-align: center;
            padding-top: 0.28rem;

            h2 {
                font-size: 0.31rem;
                padding: 0;
            }

            p {
                margin-top: 0.28rem;
                font-size: 0.21rem;
                padding: 0;
            }
        }

        .contact {
            margin-top: 0.3rem;
            background-color: #fff;
            width: 100%;
            height: 4.66rem;
            padding: 0.3rem 0;
            font-size: 0.21rem;

            .w {
                background: url(../assets/images/m-contact.png) no-repeat center top;
                background-size: 6.95rem 4.03rem;
                width: 6.95rem;
                height: 4.03rem;

                img {
                    width: 0.23rem;
                    height: 0.23rem;
                }

                .Gmail {
                    padding-top: 0.24rem;
                    padding-left: 0.5rem;

                    h5 {
                        font-size: 0.21rem;
                    }

                    p {
                        margin-top: 0.38rem;
                        font-size: 0.21rem;
                    }
                }

                .Address {
                    padding-top: 0.24rem;
                    padding-left: 0.5rem;

                    h5 {
                        font-size: 0.21rem;
                    }

                    p {
                        width: 100%;
                        margin: 0.1rem 0 0;
                        font-size: 0.21rem;
                    }
                }
            }
        }

        .Foundation {
            background: url(../assets/images/m-Foundation.png) no-repeat center top;
            height: 2.37rem;

            p {
                width: 6.32rem;
                font-size: 0.17rem;
                padding-top: 0.45rem;
            }

            .edao {
                width: 3.73rem;
                height: 0.47rem;
                font-size: 0.17rem;
                line-height: 0.47rem;
                margin: 0.3rem auto 0;
            }
        }

        .timeInfo {
            height: 19rem;
            width: 100%;
            color: #282828;
            margin-top: 40px;
            position: relative;

            // background: red;
            .timeInfo-title {
                font-size: 0.42rem;
            }

            .timeInfo-item {
                display: block;
                // justify-content: space-between;

                .timeInfo-item-left {
                    width: 100%;

                    .timeInfo-item-left-text {
                        height: 1.7rem;
                        margin-top: 0.5rem;
                        background: rgba(0, 0, 0, 0.3);
                        padding: 0.2rem 0.4rem;
                        border-radius: 0.2rem;

                        .timeInfo-item-left-text-title {
                            margin-bottom: 0.1rem;
                            font-size: 0.4rem;
                            color: #2387FF;
                        }

                        .timeInfo-item-left-text-cn {
                            font-size: 0.2rem;
                        }
                    }
                }

                .timeInfo-item-middle {
                    display: none;
                }

                .timeInfo-item-right {
                    width: 100%;
                    margin-top: 0;
                    // position: relative;

                    .timeInfo-item-right-bi {
                        position: absolute;
                        top: 165px;
                        right: 0px;
                        z-index: 1;
                    }

                    .timeInfo-item-left-text {
                        height: 2rem;
                        margin-top: 0.5rem;
                        background: rgba(0, 0, 0, 0.3);
                        padding: 0.2rem 0.4rem;
                        border-radius: 0.2rem;
                        position: relative;
                        z-index: 999;

                        .timeInfo-item-left-text-title {
                            margin-bottom: 0.1rem;
                            font-size: 0.44rem;
                            color: #2387FF;
                        }

                        .timeInfo-item-left-text-cn {
                            font-size: 0.2rem;
                        }
                    }

                    .timeInfo-item-left-text:nth-child(2) {
                        height: 2.5rem;
                    }
                }

            }

            .w {
                position: relative;

                .coin4 {
                    // position: absolute;
                    // bottom: -210px;
                    // right: 0px;
                    display: none;
                }

                .coin5 {
                    // position: absolute;
                    // bottom: -130px;
                    // left: 0px;
                    // z-index: 1;
                    display: none;
                }

                .coin6 {
                    display: block;
                    position: absolute;
                    bottom: -1rem;
                    left: 0rem;
                    z-index: 1;
                    width: 0.93rem;
                    height: 0.93rem;
                }

                .coin7 {
                    display: block;
                    position: absolute;
                    bottom: -0.9rem;
                    right: 0rem;
                    z-index: 1;
                    width: 1.08rem;
                    height: 1.06rem;
                }
            }
        }
    }
}
</style>

<template>
    <div class="footer">
        <div class="footer-header w">
            <div class="footer-header-top">
                <div class="footer-header-top-left">
                    <div class="footer-header-top-logo">
                        <img src="../assets/images/TDT/logo.png" alt="">
                    </div>
                    <div class="footer-header-top-email">
                        <div class="footer-header-top-email-text">
                            <div class="footer-header-top-email-cursor">
                                <span>BitHive</span>
                                <img src="../assets/images/c.png" alt="">
                                <span>2023</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-header-top-right fr">
                    <img @click="change(0)" src="../assets/images/twitter.png" alt="">
                    <img @click="change(1)" src="../assets/images/telegram.png" alt="">
                    <img @click="change(2)" src="../assets/images/email.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pearcoin1 from '@/assets/images/logoIcon/pearcoin-1.png'
import CoinGalaxy1 from '@/assets/images/logoIcon/CoinGalaxy-1.png'
import KonsorTech1 from '@/assets/images/logoIcon/KonsorTech-1.png'
import NodeX1 from '@/assets/images/logoIcon/NodeX-1.png'
import pearcoin from '@/assets/images/logoIcon/pearcoin.png'
import CoinGalaxy from '@/assets/images/logoIcon/CoinGalaxy.png'
import KonsorTech from '@/assets/images/logoIcon/KonsorTech.png'
import NodeX from '@/assets/images/logoIcon/NodeX.png'
import twitter from '@/assets/images/logoIcon/twitter-icon.png'
import discord from '@/assets/images/logoIcon/discord-icon.png'
import telegram from '@/assets/images/logoIcon/telegram-icon.png'
import facebook from '@/assets/images/logoIcon/facebook-icon.png'
import github from '@/assets/images/logoIcon/github-icon.png'
import medium from '@/assets/images/logoIcon/medium-icon.png'
import youtube from '@/assets/images/logoIcon/youtube-icon.png'
import twitter1 from '@/assets/images/logoIcon/twitter-icon-1.png'
import discord1 from '@/assets/images/logoIcon/discord-icon-1.png'
import telegram1 from '@/assets/images/logoIcon/telegram-icon-1.png'
import facebook1 from '@/assets/images/logoIcon/facebook-icon-1.png'
import github1 from '@/assets/images/logoIcon/github-icon-1.png'
import medium1 from '@/assets/images/logoIcon/medium-icon-1.png'
import youtube1 from '@/assets/images/logoIcon/youtube-icon-1.png'
import downloadjs from 'downloadjs'

export default {
    name: 'page-footer',
    data() {
        return {
            year: new Date().getFullYear(),
            listData: [
                {
                    alt: 'pearcoin',
                    img: pearcoin1,
                    link: 'https://www.pearcoin.ltd/'
                },
                {
                    alt: 'CoinGalaxy',
                    img: CoinGalaxy1,
                    link: window.localStorage.getItem('language') === 'zh-CN' ? 'https://www.cgxyex.co/#/' : 'https://www.cgxyex.com/#/'
                },
                {
                    alt: 'KonsorTech',
                    img: KonsorTech1,
                    link: 'https://konsortech.xyz/'
                },
                {
                    alt: 'NodeX',
                    img: NodeX1,
                    link: 'https://indonode.dev/'
                }
            ],
            twitter,
            discord,
            telegram,
            facebook,
            github,
            medium,
            youtube,
            selectedLanguage: '',
            isDropdownOpen: false
        }
    },
    methods: {
        // pc点击跳转
        change: function (index) {
            if (index === 0) {
                window.open('https://twitter.com/BithiveWallet')
            } else if (index === 1) {
                window.open('https://t.me/BitHiveWallet')
            } else if (index === 2) {
                window.open('mailto:service@bithive.cc')
            } 
        },

        /**
         * 死路由跳转
         */
        jumper(nameOrURI) {
            const re = /^(ftp|https?):\/\//
            if (re.test(nameOrURI)) {
                if (nameOrURI === 'https://www.cgxyex.co/#/' || nameOrURI === 'https://www.cgxyex.com/#/') {
                    if (window.localStorage.getItem('language') === 'zh-CN') {
                        window.open('https://www.cgxyex.co/#/')
                    } else {
                        window.open('https://www.cgxyex.com/#/')
                    }
                } else {
                    window.open(nameOrURI)
                }
            } else {
                const uri = this.$router.resolve({ name: nameOrURI }).href
                location.href = uri
            }
        },
        // 合作伙伴鼠标移入
        mouseOver(data) {
            if (data === 'pearcoin') {
                this.listData[0].img = pearcoin
            } else if (data === 'CoinGalaxy') {
                this.listData[1].img = CoinGalaxy
            } else if (data === 'KonsorTech') {
                this.listData[2].img = KonsorTech
            } else if (data === 'NodeX') {
                this.listData[3].img = NodeX
            }
        },
        // 合作伙伴鼠标移出
        mouseLeave(data) {
            if (data === 'pearcoin') {
                this.listData[0].img = pearcoin1
            } else if (data === 'CoinGalaxy') {
                this.listData[1].img = CoinGalaxy1
            } else if (data === 'KonsorTech') {
                this.listData[2].img = KonsorTech1
            } else if (data === 'NodeX') {
                this.listData[3].img = NodeX1
            }
        },
        // 加入我们鼠标移入
        mouseOverJOINUS(index) {
            if (index === 0) {
                this.twitter = twitter1
            } else if (index === 1) {
                this.discord = discord1
            } else if (index === 2) {
                this.telegram = telegram1
            } else if (index === 3) {
                this.facebook = facebook1
            } else if (index === 4) {
                this.github = github1
            } else if (index === 5) {
                this.medium = medium1
            } else if (index === 6) {
                this.youtube = youtube1
            }
        },
        // 合作伙伴鼠标移出
        mouseLeaveJOINUS(index) {
            if (index === 0) {
                this.twitter = twitter
            } else if (index === 1) {
                this.discord = discord
            } else if (index === 2) {
                this.telegram = telegram
            } else if (index === 3) {
                this.facebook = facebook
            } else if (index === 4) {
                this.github = github
            } else if (index === 5) {
                this.medium = medium
            } else if (index === 6) {
                this.youtube = youtube
            }
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        selectLanguage(language) {
            if (language == 'zh-CN') {
                this.selectedLanguage = 'CN'
            } else {
                this.selectedLanguage = 'EN';
            }
            this.$store.commit(
                'SETLANGUAGE',
                language
            )
            this.isDropdownOpen = false;
        },
        // 下载白皮书
        downloadPDF(url) {
            downloadjs(url)
        },
        linkRoadmap() {
            alert(1)
        }
    },
    mounted() {
        const internationalization = window.localStorage.getItem('language')
        if (internationalization == 'zh-CN') {
            this.selectedLanguage = 'CN'
        } else {
            this.selectedLanguage = 'EN';
        }
    },
    updated() {
        const internationalization = window.localStorage.getItem('language')
        if (internationalization == 'zh-CN') {
            this.selectedLanguage = 'CN'
        } else {
            this.selectedLanguage = 'EN';
        }
    }
}
</script>

<style lang="less">
.w {
    width: 1200px;
    margin: 0 auto;
}

.footer {
    width: 100%;
    background-color: #1C1C1F;
    z-index: 999;
    position: relative;
    height: 200px;

    .footer-header {
        padding-top: 73px;

        .footer-header-top {
            display: flex;
            padding-bottom: 30px;
            border-bottom: 1px solid #21252C;
            justify-content: space-between;

            .footer-header-top-left {
                width: 30%;

                .footer-header-top-logo {
                    img {
                        width: 135px;
                    }
                }

                .footer-header-top-email {
                    padding-top: 15px;
                    display: flex;

                    .footer-header-top-email-text {
                        color: #A7A7A7;
                        font-size: 14px;

                        .footer-header-top-email-cursor {
                            img {
                                margin: 7px;
                            }
                        }
                    }
                }
            }

            .footer-header-top-right {
                width: 130px;
                padding-top: 5px;
                display: flex;
                justify-content: space-between;

                img {
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                }
            }

        }

    }
}
</style>
<!-- 移动端-->
<style lang="less">
@media only screen and (max-width: 990px) {
    .w {
        width: 100%;
        margin: 0 auto;
    }

    .footer {
        // height: 9rem;
        background: rgba(0, 0, 0, 0.98);

        .footer-header {
            width: 100%;
            padding-top: 0.4rem;

            .footer-header-top {
                display: flex;


                .footer-header-top-left {
                    box-sizing: border-box;
                    width: 50%;
                    font-size: 0.22rem;
                    padding-left: 0.3rem;

                    .footer-header-top-logo {
                        cursor: pointer;

                        img {
                            width: 2.13rem;
                        }
                    }

                    .footer-header-top-email {
                        padding-top: 0.5rem;
                        display: flex;

                        .footer-header-top-email-text {
                            color: #ffffff;

                            .footer-header-top-email-cursor {
                                cursor: pointer;
                                margin-bottom: 0.2rem;
                            }
                        }
                    }
                }

                .footer-header-top-right {
                    box-sizing: border-box;
                    width: 42%;
                    color: #ffffff;
                    display: flex;
                    justify-content: space-between;
                    padding-top: 0;
                    padding-right: 0.3rem;

                    .mod_help_item {
                        margin-bottom: 0.4rem;

                        .title {
                            color: #2387FF;
                            padding-bottom: 0.2rem;
                            font-size: 0.24rem;
                        }

                        dd {
                            font-size: 0.22rem;
                            padding-bottom: 0.1rem;
                        }

                        a {

                            color: #ffffff;
                        }
                    }

                    .container {
                        display: none;
                    }
                }

            }

            .footer-header-bottom {
                color: #ffffff;
                font-size: 0.2rem;
                margin-right: 0.2rem;
                padding: 0.5rem;

                .footer-header-bottom-text {
                    display: flex;
                    justify-content: flex-end;
                    text-align: right;
                    margin-bottom: 0.3rem;
                }
            }
        }
    }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { t } from './assets/i18n/index'
import VTitle from 'vue-wechat-title'
import axios from 'axios'

import './assets/less/theme.default.less'

import ElementUI from 'element-ui' // element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'// element-ui的css

// import 'swiper/dist/css/swiper.min.css'
// import 'swiper/dist/js/swiper.min'

Vue.config.productionTip = false
Vue.use(VTitle)
Vue.use(ElementUI)

Vue.filter('t', t)
//  const $ajax = axios.create({
//    baseURL: process.env.NODE_ENV === 'production'
//        ? ''
//        : ''
//  })

const $ajax = axios.create({
    // baseURL: 'http://ttapi.xxxx.itcast.cn/',
    // baseURL: 'http://xxxxxx.itheima.net/',
    // 将所有的请求全部以/api开头，用于匹配反向代理
    baseURL: '',
    // 超时， 如果接口超过3000毫秒，直接失败
    timeout: 5000
})

Object.defineProperties(Vue.prototype, {
    $ajax: { value: $ajax }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

<template>
    <div id="app">
        
        <router-view />
        <page-footer></page-footer>
    </div>
</template>
<script>
import header from '@/components/header.vue'
import footer from '@/components/footer.vue'
export default {
    data() {
        return {
            screenWidth: null
        }
    },
    components: { pageHeader: header, pageFooter: footer },
    mounted() {
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },

    watch: {
        screenWidth: function (n, o) {
            if (this.screenWidth < 990) {
                document.querySelector('html').style.fontSize = 100 * this.screenWidth / 750 + 'px'
            } else {
                document.querySelector('html').style.fontSize = ''
            }
        }
    }
}
</script>
<style lang="less"></style>

<!-- 生态 -->
<template>
  <div class="ecological">
    <div class="advantage">
      <div class="w">
        <div class="advantage-top">
          <div class="advantage-top-l">
            <div class="advantage-top-ll">
              <img src="../assets/images/wallet/BitHive.png" alt="" @click="home">
              <span class="advantage-top-ll-title">{{ "News" | t }}</span>
            </div>
            <div class="advantage-top-lr">{{ "bai" | t }}</div>
          </div>
          <div class="advantage-top-r">
            <el-input prefix-icon="el-icon-search" size="small" v-model="input" @input="handleInput">
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="w">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="tabs.tab1 | t" name="one">
            <div class="tabs-first">
              <div class="content" v-for="(item, index) in filteredCardList" :key="index">
                <img :src="item.image" alt="">
                <div class="content-text">
                  {{ item.title }}
                </div>
                <div class="content-text">
                  {{ item.summary }}
                </div>
                <div class="content-timeButton">
                  <div>{{ item.publishTime }}</div>
                  <div>
                    <span class="content--text">
                      {{ item.subTypeName }}
                    </span>
                    <span class="content-button-add" @click="add(item.id)">{{ "readmore" | t }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="tabs.tab2 | t" name="first">
            <div class="tabs-first">
              <div class="content" v-for="(item, index) in filteredCardList" :key="index">
                <img :src="item.image" alt="">
                <div class="content-text">
                  {{ item.title }}
                </div>
                <div class="content-text">
                  {{ item.summary }}
                </div>
                <div class="content-timeButton">
                  <div>{{ item.publishTime }}</div>
                  <div>
                    <span class="content--text">
                      {{ item.subTypeName }}
                    </span>
                    <span class="content-button-add" @click="add(item.id)">{{ "readmore" | t }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="tabs.tab3 | t" name="second">
            <div class="tabs-first">
              <div class="content" v-for="(item, index) in filteredCardList" :key="index">
                <img :src="item.image" alt="">
                <div class="content-text">
                  {{ item.title }}
                </div>
                <div class="content-text">
                  {{ item.summary }}
                </div>
                <div class="content-timeButton">
                  <div>{{ item.publishTime }}</div>
                  <div>
                    <span class="content--text">
                      {{ item.subTypeName }}
                    </span>
                    <span class="content-button-add" @click="add(item.id)">{{ "readmore" | t }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="tabs.tab4 | t" name="third">
            <div class="tabs-first">
              <div class="content" v-for="(item, index) in filteredCardList" :key="index">
                <img :src="item.image" alt="">
                <div class="content-text">
                  {{ item.title }}
                </div>
                <div class="content-text">
                  {{ item.summary }}
                </div>
                <div class="content-timeButton">
                  <div>{{ item.publishTime }}</div>
                  <div>
                    <span class="content--text">
                      {{ item.subTypeName }}
                    </span>
                    <span class="content-button-add" @click="add(item.id)">{{ "readmore" | t }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="tabs.tab5 | t" name="fourth">
            <div class="tabs-first">
              <div class="content" v-for="(item, index) in filteredCardList" :key="index">
                <img :src="item.image" alt="">
                <div class="content-text">
                  {{ item.title }}
                </div>
                <div class="content-text">
                  {{ item.summary }}
                </div>
                <div class="content-timeButton">
                  <div>{{ item.publishTime }}</div>
                  <div>
                    <span class="content--text">
                      {{ item.subTypeName }}
                    </span>
                    <span class="content-button-add" @click="add(item.id)">{{ "readmore" | t }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="tabs.tab6 | t" name="five">
            <div class="tabs-first">
              <div class="content" v-for="(item, index) in filteredCardList" :key="index">
                <img :src="item.image" alt="">
                <div class="content-text">
                  {{ item.title }}
                </div>
                <div class="content-text">
                  {{ item.summary }}
                </div>
                <div class="content-timeButton">
                  <div>{{ item.publishTime }}</div>
                  <div>
                    <span class="content--text">
                      {{ item.subTypeName }}
                    </span>
                    <span class="content-button-add" @click="add(item.id)">{{ "readmore" | t }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="tabs.tab7 | t" name="six">
            <div class="tabs-first">
              <div class="content" v-for="(item, index) in filteredCardList" :key="index">
                <img :src="item.image" alt="">
                <div class="content-text">
                  {{ item.title }}
                </div>
                <div class="content-text">
                  {{ item.summary }}
                </div>
                <div class="content-timeButton">
                  <div>{{ item.publishTime }}</div>
                  <div>
                    <span class="content--text">
                      {{ item.subTypeName }}
                    </span>
                    <span class="content-button-add" @click="add(item.id)">{{ "readmore" | t }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import cex1 from '../assets/images/wallet/cehsi.png'

export default {
  name: 'ecological-page',
  data() {
    return {
      input: '',
      activeName: 'one',
      type: '',
      language: '',
      cardList: [],
      secondList: [
        {
          id: 1,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分',
          usdt: '200.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝',
          time: '2023 年 8 月 11 日',
          text: '产品'
        },
        {
          id: 2,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        }
      ],
      thirdList: [
        {
          id: 1,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分',
          usdt: '200.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝',
          time: '2023 年 8 月 11 日',
          text: '产品'
        },
        {
          id: 2,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 3,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 4,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 5,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        }
      ],
      fourthList: [
        {
          id: 1,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分',
          usdt: '200.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝',
          time: '2023 年 8 月 11 日',
          text: '产品'
        },
        {
          id: 2,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 3,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 4,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 5,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        }
      ],
      fiveList: [
        {
          id: 1,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分',
          usdt: '200.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝',
          time: '2023 年 8 月 11 日',
          text: '产品'
        },
        {
          id: 2,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 3,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 4,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 5,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        }
      ],
      sixList: [
        {
          id: 1,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分',
          usdt: '200.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝',
          time: '2023 年 8 月 11 日',
          text: '产品'
        },
        {
          id: 2,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 3,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 4,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        },
        {
          id: 5,
          img: cex1,
          content: '庆祝品牌升级，参与合作交易划分1',
          usdt: '210.000 USDT',
          Wallet: 'BitHive Wallet 合约狂欢活动第六期上线！为庆祝1',
          time: '2023 年 8 月 12 日',
          text: '产品2'
        }
      ],
      tabs: {
        tab1: 'all',
        tab2: 'product',
        tab3: 'activity',
        tab4: 'announcement',
        tab5: 'industry',
        tab6: 'business',
        tab7: 'guide',
      }
    }
  },
  mounted() {
    const ss = localStorage.getItem('language')
    if (ss == 'zh-CN') {
      this.language = 'zh-CN'
    } else {
      this.language = 'en-US'
    }
    this.first()
    this.tabsList()
  },
  computed: {
    filteredCardList() {
      if (!this.input) {
        return this.cardList;
      } else {
        const filteredData = this.cardList.filter(item => {
          return item.title.toLowerCase().includes(this.input.toLowerCase());
        });
        return filteredData;
      }
    }
  },
  methods: {
    // 默认加载
    async first() {
      const { data: res } = await this.$ajax.post('/usercenter/info/list', {
        type: 1000,
        subType: this.type
      }, {
        headers: {
          'Accept-Language': this.language
        }
      });

      this.cardList = [...res.data.records]
      // console.log('ressss', this.cardList);

    },
    // tabs数据，未使用
    async tabsList() {
      const { data: res } = await this.$ajax.get('/usercenter/info/type',
        {
          params: {
            type: 1000
          }
        }
      );
      const ss = localStorage.getItem('language')
      let newArray = []; // 存储结果的新数组

      if (ss === 'zh-CN') {
        newArray = res.data.map(item => {
          return {
            name: item.name,
            subTypes: item.subTypes,
            type: item.type
          };
        });
      } else {
        newArray = res.data.map(item => {
          return {
            name: item.enName,
            subTypes: item.subTypes,
            type: item.type
          };
        });
      }
      // console.log('newArray', newArray)
    },
    // 搜索
    handleInput() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        // console.log('this.input', this.input);
        const filteredCardList = this.cardList.filter(item => {
          return item.content.includes(this.input);
        });
        // console.log('filteredCardList', filteredCardList);
      }, 600);
    },
    // tab切换
    handleClick(tab, event) {
      if (tab.name == 'first') {
        this.cardList = []
        this.type = 1001
        this.first()
      } else if (tab.name == 'second') {
        this.cardList = []
        this.type = 1002
        this.first()
      }
      else if (tab.name == 'third') {
        this.cardList = []
        this.type = 1003
        this.first()
      }
      else if (tab.name == 'fourth') {
        this.cardList = []
        this.type = 1004
        this.first()
      }
      else if (tab.name == 'five') {
        this.cardList = []
        this.type = 1005
        this.first()
      }
      else if (tab.name == 'six') {
        this.cardList = []
        this.type = 1006
        this.first()
      }
      else if (tab.name == 'one') {
        this.cardList = []
        this.type = ''
        this.first()
      }
    },
    // 阅读更多
    add(e) {
      // console.log(e)
      this.$router.push(
        {
          name: 'details',
          query: { id: e }
        }
      )
    },
    // 返回首页
    home() {
      this.$router.push(
        {
          name: 'home'
        }
      )
    }
  }
}
</script>
<style lang="less" >
.ecological {
  .el-tabs__nav-wrap::after {
    background-color: transparent !important;
  }

  .el-tabs .el-tabs__header .el-tabs__nav-wrap .is-active {
    color: #FFF;
    background-color: #FEA800;
    border-radius: 6px;
  }

  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 20px;
  }

  .el-tabs__item {
    margin-left: 10px;
    color: #000000;
  }

  .el-tabs .el-tabs__header .el-tabs__nav-wrap .el-tabs__item:hover {
    color: #FFF;
    background-color: #FEA800;
    border-radius: 6px;
  }

  .el-tabs--top .el-tabs__item.is-top:last-child {
    padding-right: 20px;
  }

  .el-tabs__header {
    margin: 0px;
  }

  .el-tabs__nav {
    margin-bottom: 10px;
  }

  .el-tabs__active-bar {
    height: 0 !important;
  }

  .advantage {
    font-family: 'MiSans';
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;

    .advantage-top {
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .advantage-top-l {
        .advantage-top-ll {
          margin-bottom: 20px;

          img {
            cursor: pointer;
            vertical-align: middle;
          }

          .advantage-top-ll-title {
            vertical-align: middle;
            margin-left: 10px;
            font-size: 20px;
            font-weight: bold;
          }
        }
      }

      .advantage-top-r {
        .el-input--small .el-input__inner {
          border: none;
        }
      }
    }
  }

  .tabs {
    margin-bottom: 30px;
    margin-top: 20px;

    .tabs-first {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;
      min-height: 493px;

      .content {
        // width: 380px;
        // height: 400px;
        // background: red;
        margin-top: 20px;
        margin-right: 20px;

        img {
          min-width: 380px;
          min-height: 230px;
          max-width: 380px;
          max-height: 230px;
        }

        .content-text {
          margin: 10px 0;
        }

        .content-timeButton {
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .content--text {
            margin-right: 10px;
          }

          .content-button-add {
            padding: 2px 6px;
            border: 1px solid #FEA800;
            color: #FEA800;
            border-radius: 20px;
            cursor: pointer;
          }
        }
      }
    }

  }
}
</style>



<!-- 移动端 -->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
  /deep/.el-tabs__nav-next {
    top: 30px !important;
  }

  /deep/.el-tabs__nav-prev {
    top: 30px !important;
  }

  .ecological {
    background-size: cover !important;

    .advantage {
      // background: url('../assets/images/shift-bg3.png') no-repeat center;
      background-size: 7.5rem 6.64rem;
      height: 5rem;
      .w{
        width: 7rem;
      }
      .advantage-top {
        height: auto;
        display: inline-block;

        .advantage-top-lr {
          font-size: 0.3rem;
        }
      }
    }

    .tabs {
      background-size: 7.5rem 6.64rem;
      // height: 5rem;
      .tabs-first{
        display: block;
      }

      .w {
        width: 7rem;
      }
      .content{
        margin-right: 0;
        font-size: 0.3rem;
        img{
          min-width: 100%;
          max-width: 100%;
        }
      }

    }
  }
}</style>

<template>
    <div class="home">
        <page-header @lang="changelang"></page-header>
        <div class="troduce">
            <div class="w" :class="{ 'Chinese': lang }">
                <div class="welcomeImg">
                    <img src="../assets/images/TDT/welcome.png" alt="">
                </div>
                <h1 class="welcome">
                    <span>{{ "Wallet" | t }}</span>
                </h1>
                 <p class="ph">
                    {{ "Conveniently" | t }}
                </p>
                <div class="phs">
                    <div class="Android fl" @click="change(1)">
                        <img src="../assets/images/download.png" alt="">
                        <span>Android</span>
                    </div>
                    <div class="code fl">
                        <img src="../assets/images/code.png" alt="">
                        <div class="code2">
                            <div id="qrcode">
                                <img style="width: 120px;height: 120px;" src="../assets/images/code3.png" alt="">
                            </div>
                            <img src="../assets/images/icon-512.png" alt="" class="images">
                            <div>{{ "Download" | t }}</div>
                        </div>
                    </div>
                     <!-- <div class="Chrome fr" @click="change(0)">
                        <img src="../assets/images/Chrome.png" alt="">
                        <span>Chrome</span>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="num">
            <div class="w" :class="{ 'Chinese': lang }">
                <div class="secure">
                    <h3>{{ "Safe" | t }}</h3>
                    <p class="p1">{{ "decentralized" | t }}</p>
                    <p class="p2">{{ "chains" | t }}</p>
                </div>
                <div class="block">
                    <img src="../assets/images/block.png" alt="">
                </div>

            </div>
        </div>

        <div class="advantage">
            <div class="w">
                <img class="convenient" src="../assets/images/convenient.png" alt="">
                <div class="why">
                    <h3>{{ "Convenient" | t }}</h3>
                    <p class="p1">{{ "Assets" | t }}</p>
                    <p class="p2">{{ "Effortless" | t }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import downloadjs from 'downloadjs'
import Discordss from '@/assets/images/Discordss.png'
import Discord1 from '@/assets/images/Discord1.png'
import GitHub from '@/assets/images/GitHub.png'
import GitHub1 from '@/assets/images/GitHub1.png'
import telegram from '@/assets/images/telegram.png'
import telegram1 from '@/assets/images/telegram1.png'
import twitter from '@/assets/images/twitter.png'
import twitter1 from '@/assets/images/twitter1.png'
import header from '@/components/header.vue'
import QRCode from 'qrcodejs2'

export default {
    name: 'home-page',
    data() {
        return {
            startVal: 0,
            startVals: 0,
            startValss: 0,
            endVal: 0,
            endVals: 0,
            endValss: 0,
            duration: 2000,
            popupState: true,
            transactions: '',
            height: '',
            TotalStake: '',
            timer: null,
            startTime: 0, // 初始开始请求时间
            currentIndex: 0,
            userShow: true,
            terraceList: [{
                id: 1,
                path1: Discordss,
                path2: Discord1,
                transfer: ''
            }, {
                id: 2,
                path1: GitHub,
                path2: GitHub1,
                transfer: ''
            }, {
                id: 3,
                path1: telegram,
                path2: telegram1,
                transfer: ''
            }, {
                id: 4,
                path1: twitter,
                path2: twitter1,
                transfer: ''
            }],
            tabChange: '',
            randomNumber: 1,
            randomNumbers: 1,
            randomNumberss: 1,
            lang: false,
            Download:'',
        }
    },
    components: {
        pageHeader: header
    },
    methods: {
        changelangs(){
            const language = localStorage.getItem('language')
            // console.log("language",language);
            if (language == 'zh-CN') {
                this.lang = true
            } else {
                this.lang = false
            }
        },
        changelang(message) {
            if (message == 'ZN') {
                this.lang = true
            } else {
                this.lang = false
            }
        },
        getLanguage() {
            if (window.localStorage.getItem('language') === 'zh-CN') {
                this.lang = true
            } else {
                this.lang = false
            }
        },
        download() {
            const lang = this.$store.getters.getLanguage
            // location.href = `/static/pdf/ICWChain-whitePaper-${lang}.pdf`
            const pdfUrl = 'https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/ICWChain-whitePaper-' + lang + '.pdf'
            downloadjs(pdfUrl)
        },
        popupClick() {
            this.popupState = false
        },
        jumper(nameOrURI) {
            const re = /^(ftp|https?):\/\//
            if (re.test(nameOrURI)) {
                if (nameOrURI === 'https://www.cgxyex.co/#/' || nameOrURI === 'https://www.cgxyex.com/#/') {
                    if (window.localStorage.getItem('language') === 'zh-CN') {
                        window.open('https://www.cgxyex.co/#/')
                    } else {
                        window.open('https://www.cgxyex.com/#/')
                    }
                } else {
                    window.open(nameOrURI)
                }
            } else {
                const uri = this.$router.resolve({ name: nameOrURI }).href
                location.href = uri
            }
        },
        async getTransactions() {
            this.randomNumber = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/', {
                jsonrpc: '2.0',
                method: 'getCoinInfo',
                params: [
                    1
                ],
                id: 250
            })
            let txCount = res.result.txCount
            txCount = this.addDou(txCount.toString())
            this.transactions = txCount
            this.endVal = res.result.txCount
            this.startVal = this.endVal - this.randomNumber
        },

        async getBlockheight() {
            this.randomNumbers = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.get('/usercenter/version/latest?type=10')
            this.Download = res.data
            let qrcode = new QRCode('qrcode', {
                width: 120,
                height: 120,
                colorDark: '#000000',
                colorLight: '#ffffff'
            })
            qrcode.makeCode(res.data)
        },

        async getTotalStake() {
            this.randomNumberss = Math.round(Math.random() * 9999)
            const { data: res } = await this.$ajax.post('/api/api/',
                {
                    jsonrpc: '2.0',
                    method: 'getCoinInfo',
                    params: [
                        1
                    ],
                    id: 508
                }
            )
            let TotalStake = res.result.consensusTotal
            TotalStake = this.thousands(TotalStake.toString())
            this.TotalStake = TotalStake
            this.endValss = parseFloat(res.result.consensusTotal.toString().slice(0, 9))
            this.startValss = this.endValss - this.randomNumberss
        },
        addDou(num) {
            const numArr = num.split('.')
            num = numArr[0]
            let result = ''
            while (num.length > 3) {
                result = ',' + num.slice(-3) + result
                num = num.slice(0, num.length - 3)
            }
            if (num) {
                result = num + result
            }
            return result
        },

        thousands(num) {
            num = num / 100000000000
            var str = num.toFixed(2).toString()
            var reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
            return str.replace(reg, '$1,')
        },
        // pc点击跳转
        change: function (index) {
            window.open('https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/BitHive-1.0.9.apk');
            //const that = this
            //if (index === 0) {
            //    window.open('https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/BitHive%20Wallet.zip')
            //} else if (index === 1) {
            //    window.open(that.Download)
            //}
        },
        // 鼠标移入切换图片
        over(e) {
            this.terraceList[e].transfer = this.terraceList[e].path1
            this.terraceList[e].path1 = this.terraceList[e].path2
            this.tabChange = e
        },
        // 鼠标移出切换图片
        out(e) {
            this.terraceList[e].path1 = this.terraceList[e].transfer
            this.tabChange = e
        },
        // 移动端手指触碰
        gtouchstart(index) {
            // console.log('index', index)
            this.terraceList[index].transfer = this.terraceList[index].path1
            this.terraceList[index].path1 = this.terraceList[index].path2
            this.tabChange = index
        },
        // 移动端手指滑动
        gtouchmove(index) {
            // console.log('index1', index)
        },
        // 移动端手指离开
        gtouchend(index) {
            setTimeout(() => {
                this.terraceList[index].path1 = this.terraceList[index].transfer
            }, 1000)
            this.tabChange = index
        },
        // 移动端点击跳转页面
        learnMorefirst() {
            window.open('https://discord.gg/XrUdhYY8')
        },
        learnMoretwo() {
            // window.open('https://github.com/icwchain')
        },
        learnMorethree() {
            window.open('https://t.me/TridentChain')
        },
        learnMorefour() {
            window.open('https://twitter.com/Trident_Chain')
        }
    },
    created() {
        this.getBlockheight()
        this.changelangs()
    },

}
</script>
<style lang="less" scoped>
.home {

    .troduce {
        width: 100%;
        height: 1200px;
        margin: 0 auto;

        .w {
            position: relative;
        }

        iframe.ap.max {
            border: none;
        }

        .welcomeImg {
            position: absolute;
            width: 534px;
            height: 698px;
            left: 0;
            top: 243px;
        }

        .Chinese {
            .welcome {
                top: 417px;
            }

            .ph {
                top: 505px;
            }
        }

        .welcome {
            font-family: 'SourceHanSansCN-Bold';
            width: 618px;
            height: 48px;
            color: #282828;
            font-size: 46px;
            position: absolute;
            top: 270px;
            left: 582px;
            font-weight: bold;
        }

        .ph {
            font-family: 'SourceHanSansCN-Medium';
            width: 618px;
            height: 29px;
            color: #282828;
            font-size: 30px;
            position: absolute;
            top: 476px;
            left: 582px;
        }

        .phs {
            position: absolute;
            top: 613px;
            left: 582px;

            .Android {
                width: 204px;
                height: 60px;
                border-radius: 30px;
                background-color: #FFAA00;
                cursor: pointer;

                img {
                    margin: 17px 15px 17px 50px;
                }

                span {
                    color: #FFFFFF;
                    font-size: 17px;
                    line-height: 60px;
                }
            }

            .code {
                width: 61px;
                height: 61px;
                border-radius: 50%;
                border: 1px solid #282828;
                margin-left: 30px;
                margin-right: 30px;
                cursor: pointer;

                img {
                    margin: 17px;
                }

                .code2 {
                    position: absolute;
                    width: 148px;
                    height: 172px;
                    top: 81px;
                    left: 190px;
                    text-align: center;
                    box-shadow: 0px 4px 20px 0px rgba(212, 212, 212, 0.3);
                    display: none;

                    #qrcode {
                        margin: 14px;
                    }

                    img {
                        margin: 0;
                    }

                    .images{
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        top: 60px;
                        left: 60px;
                    }
                }

                &:hover {
                    .code2 {
                        display: block;
                    }
                }
            }

            .Chrome {
                width: 204px;
                height: 60px;
                border-radius: 30px;
                border: 1px solid #282828;
                cursor: pointer;

                img {
                    margin: 17px 15px 17px 50px;
                }

                span {
                    color: #282828;
                    font-size: 18px;
                    line-height: 60px;
                }
            }
        }
    }

    .num {
        width: 100%;
        height: 750px;
        margin-top: 60px;

        .w {
            position: relative;
        }

        .Chinese {
            .secure {
                top: 100px;

                .p2 {
                    margin-top: 30px;
                }
            }


        }

        .secure {
            position: absolute;
            top: 10px;
            left: 0;
            width: 430px;
            margin-top: 10px;
            text-align: left;
            color: #282828;

            h3 {
                margin-top: 40px;
                font-size: 50px;
                font-weight: 700;
                line-height: 40px;
                font-family: 'SourceHanSansCN-Bold';
            }

            .p1 {
                margin-top: 30px;
                height: 135px;
                font-size: 30px;
            }

            .p2 {
                margin-top: 155px;
                height: 69px;
                font-size: 30px;
            }
        }

        .block {
            position: absolute;
            top: 0;
            right: 0;
        }

    }

    .advantage {
        width: 100%;
        height: 1010px;


        .w {
            position: relative;
        }

        .convenient {
            position: absolute;
            top: 300px;
            left: 0;
            z-index: 1;
        }

        .why {
            position: absolute;
            top: 500px;
            right: 0;
            width: 414px;

            h3 {
                font-weight: 700;
                font-size: 50px;
            }


            .p1 {
                margin-top: 30px;
                font-size: 30px;
                word-wrap: break-word;
            }

            .p2 {
                margin-top: 30px;
                font-size: 30px;
                word-wrap: break-word;
            }
        }
    }
}
</style>
<!--移动端-->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
    .home {
        height: 38rem;

        .w {
            width: 6.1rem;
            margin: 0 auto;
        }

        .troduce {
            height: 17rem;
            // position: relative;

            .welcome {
                width: 100%;
                font-size: 0.45rem;
                top: 2rem;
                left: -0.1rem;
                height: auto;
            }

            .welcomeImg {
                position: absolute;
                top: 7.6rem;
                right: 0;
                width: 6rem;
                height: 6rem;

                img {
                    width: 5rem;
                }
            }

            .Chinese {
                .welcome {
                    top: 2rem;
                }

                .ph {
                    top: 3rem;
                }

                .phs {
                    top: 4rem;
                }
            }

            .ph {
                font-size: 0.24rem;
                padding: 0;
                top: 4rem;
                width: 100%;
                left: -0.1rem;
                line-height: 0.34rem;
                height: auto;
            }

            .phs {
                width: 100%;
                top: 6rem;
                left: -0.1rem;

                .Android {
                    width: 2.7rem;
                    height: 1rem;
                    border-radius: 0.5rem;

                    img {
                        width: 0.5rem;
                        height: 0.5rem;
                        margin: 0rem 0.3rem 0.7rem 0.5rem;
                    }

                    span {
                        position: absolute;
                        top: 0.24rem;
                        left: 1.1rem;
                        font-size: 0.3rem;
                        line-height: 0.5rem;
                        margin-bottom: 0.5rem;
                    }
                }

                .code {
                    display: none;
                }

                .Chrome {
                    width: 2.7rem;
                    height: 1rem;
                    border-radius: 0.5rem;
                    margin-left: 0.5rem;

                    img {
                        width: 0.5rem;
                        height: 0.5rem;
                        margin: 0.1rem 0.3rem 0.7rem 0.5rem;
                    }

                    span {
                        position: absolute;
                        top: 0.24rem;
                        right: 0.4rem;
                        font-size: 0.3rem;
                        line-height: 0.5rem;
                        margin-bottom: 0.5rem;
                    }
                }
            }

            .download {
                left: -0.1rem;
                width: 3.25rem;
                top: 6.2rem;
                height: 0.46rem;
                text-align: center;
                font-size: 0.13rem;
                font-weight: bold;
                color: #246EFF;
                line-height: 0.46rem;
                padding: 0 0;
                background: #fff;
                border: 1px solid #246EFF;
                border-radius: 0.41rem;
            }
        }

        .num {
            width: 6.94rem;
            // background-color: #fff;
            height: 11rem;
            margin: 0 0.3rem;

            .w {
                margin-top: -0.73rem;
            }

            .Chinese {
                .secure {
                    top: 1rem;
                }

                .ph {
                    top: 5rem;
                }
            }


            .secure {
                top: 0.1rem;
                width: 6.5rem;
                margin-top: 0.1rem;

                h3 {
                    font-size: 0.5rem;
                    margin-top: 0.4rem;
                    line-height: 0.5rem;
                }

                .p1 {
                    font-size: 0.24rem;
                    margin-top: 0.3rem;
                    height: 0.5rem;
                }

                .p2 {
                    font-size: 0.24rem;
                    height: 0.5rem;
                    margin-top: 0.8rem;
                }
            }

            .block {
                float: left;
                box-sizing: border-box;
                width: 100%;
                margin-top: 0.5rem;
                padding: 0;
                top: 4rem;

                img {
                    width: 5rem;
                }
            }

        }

        .advantage {
            margin-top: 0rem;
            box-sizing: border-box;
            height: 12rem;
            // background-color: red;

            .w {
                width: 6.94rem;
            }

            .why {
                top: 0.1rem;
                width: 6.5rem;
                margin-top: 0.1rem;

                h3 {
                    font-size: 0.5rem;
                    margin-top: 0.4rem;
                    line-height: 0.5rem;
                }

                .p1 {
                    font-size: 0.24rem;
                    margin-top: 0.3rem;
                    height: 0.5rem;
                }

                .p2 {
                    width: 6rem;
                    font-size: 0.24rem;
                    height: 1rem;
                    margin-top: 0.2rem;
                }
            }

            .convenient {
                float: left;
                box-sizing: border-box;
                width: 6rem;
                margin-top: 0.5rem;
                padding: 0;
                top: 4rem;
            }

        }
    }
}
</style>

<template>
    <div class="details">
        <div class="w">
            <div class="details-text">
                <div class="title">{{ tabData.title }}</div>
                <div class="time">{{ tabData.publishTime }}</div>
                <!-- <div class="img">
                    <img :src="tabData.image" alt="">
                </div> -->
                <div class="text" v-html="tabData.content"></div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'details-page',
    data() {
        return {
            id: this.$route.query.id,
            tabData: {},
            language: ''
        }
    },
    mounted() {
        // console.log('this.tabData', this.tabData);
        const ss = localStorage.getItem('language')
        if (ss == 'zh-CN') {
            this.language = 'zh-CN'
        } else {
            this.language = 'en-US'
        }
        this.detailsfn()
    },
    methods: {
        async detailsfn() {
            const { data: res } = await this.$ajax.get('/usercenter/info/detail',
                {
                    params: {
                        id: this.id
                    },
                    headers: {
                        'Accept-Language': this.language
                    }

                }
            );
            // console.log('resss999', res.data)
            this.tabData = { ...res.data }
        },
    }
}
</script>
<style lang="less" scoped>
.details {
    min-height: 654px;
    .details-text {
        margin-top: 77px;
    }

    .title {
        text-align: center;
        font-size: 24px;
    }

    .time {
        text-align: center;
        margin: 60px 0 60px 0;
    }

    .img {
        text-align: center;
        margin-bottom: 60px;

        img {
            min-height: 230px;
            min-width: 600px;
            max-height: 230px;
            max-width: 600px;
        }
    }

    .text {
        width: 600px;
        min-height: 124px;
        margin: 0 auto;
        margin-bottom: 60px;
    }
}
</style>
  
  
  
  <!-- 移动端 -->
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
    .details {
        margin-bottom: 1rem;

        .w {
            width: 7rem;
        }

        .details-text {
            margin-top: 1rem;

            .title {
                font-size: 0.4rem;
            }

            .time {
                font-size: 0.3rem;
                margin: 0.5rem 0;
            }

            .img {
                margin-bottom: 1rem;
                img {
                    min-width: 100%;
                    max-width: 100%;
                }
            }

            .text {
                font-size: 0.3rem;
                width: 100%;
                min-height: 2rem;
                margin: 0 auto;
                margin-bottom: 1rem;
            }
        }
    }
}
</style>
  